import React, { useReducer, useMemo, useEffect, createContext, useState } from "react";

import i18n from './i18n'

const loadState = (field) => {
    try {
      const serializedState = localStorage.getItem(field);
      if (serializedState === null) {
        return undefined;
      }
      return JSON.parse(serializedState);
    } catch (err) {
      return undefined;
    }
  }; 

  const saveState = (state, field) => {
    try {
      const serializedState = JSON.stringify(state);
      localStorage.setItem(field, serializedState);
    } catch {
      // ignore write errors
    }
  };


export const themeContext = createContext();
const themeReducer = (state, action) => {
    switch(action.type) {
        case 'CHANGE_THEME':
            return {...state, theme: action.payload};
        default:
            return state;
    }
}
const Provider = (props) => {
  const [hasMounted, setHasMounted] = useState(false);

  const [state, dispatch] = useReducer(
    themeReducer, null, () => {
      let savedTheme = loadState('theme')
      if (!savedTheme) return { theme: 'dark' }
      return savedTheme;
    }
  );

  const stateValue = useMemo(() => {
    return { state, dispatch }
  }, [state, dispatch]);

  useEffect(() => {
    setHasMounted(true);
  }, [])

  useEffect(() => {
    saveState(state, 'theme')
  }, [state]);

  if (!hasMounted) {
    return null;
  }

  return (
    <themeContext.Provider value={stateValue}>
      {props.children}
    </themeContext.Provider>
  )
}


export const cookiesContext = createContext();
const cookiesReducer = (state, action) => {
    switch(action.type) {
        case 'AGREE_TO_COOKIES':
            return {...state, hasAgreed: action.payload};
        default:
            return state;
    }
}
const CookiesProvider = (props) => {
  const [hasMounted, setHasMounted] = useState(false);

  const [state, dispatch] = useReducer(
    cookiesReducer, null, () => {
      let savedTheme = loadState('cookies')
      if (!savedTheme) return { hasAgreed: false }
      return savedTheme;
    }
  );

  const stateValue = useMemo(() => {
    return { state, dispatch }
  }, [state, dispatch]);

  useEffect(() => {
    setHasMounted(true);
  }, [])

  useEffect(() => {
    saveState(state, 'cookies')
  }, [state]);

  if (!hasMounted) {
    return null;
  }

  return (
    <cookiesContext.Provider value={stateValue}>
      {props.children}
    </cookiesContext.Provider>
  )
}

export default ({ element }) => (
    <Provider>
      <CookiesProvider>
        {element}
      </CookiesProvider>
    </Provider>
  )