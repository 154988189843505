import 'firebase/firestore';
import "prismjs/themes/prism-tomorrow.css";
import './src/styles/custom-code-buttons.scss';
import './src/styles/hover-anchors.scss';

import wrapWithProvider from "./stateProvider"
export const wrapRootElement = wrapWithProvider

const lang = ['/ru/', '/zh/'];
const cleanPathname = (target, pattern) => {
    let cleaned = target;
    for (let i = 0; i < pattern.length; i++) {
        if (target.includes(pattern[i])) {
            cleaned = cleaned.replace(pattern[i], '/');
            break;
        }
    }

    return cleaned;
}

export const shouldUpdateScroll = ({
    prevRouterProps: { location: previousLocation },
    routerProps: { location: newLocation },
    getSavedScrollPosition
}) => {
    let cleanedPreviousPathname = cleanPathname(previousLocation.pathname, lang);
    let cleanedNewPathname = cleanPathname(newLocation.pathname, lang);

    // console.log('prev loc: ', cleanedPreviousPathname, previousLocation);
    // console.log('new loc:', cleanedNewPathname, newLocation)

    if (cleanedPreviousPathname !== cleanedNewPathname) {
        return true;
    } else {
        return false;
    }
}

export const onClientEntry = async () => {
    if (typeof IntersectionObserver === `undefined`) {
      await import(`intersection-observer`);
    }
}