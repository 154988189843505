import React from 'react';
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import LanguageDetector from 'i18next-browser-languagedetector';

import resources_en from './locales/resources-en.json';
import resources_ru from './locales/resources-ru.json';
import resources_zh from './locales/resources-zh.json';

const resources = Object.assign({}, resources_en, resources_ru, resources_zh);

export default i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        fallbackLng: "en",
        load: 'languageOnly',

        detection: {
            lookupLocalStorage: 'i18nextLng'
        },

        // development only
        debug: false,

        //keySeparator: true,
        interpolation: {
            escapeValue: false
        }
    });
