// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-index-js": () => import("./../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-onclick-tutorials-js": () => import("./../src/pages/blog/onclick-tutorials.js" /* webpackChunkName: "component---src-pages-blog-onclick-tutorials-js" */),
  "component---src-pages-blog-tech-and-beyond-js": () => import("./../src/pages/blog/tech-and-beyond.js" /* webpackChunkName: "component---src-pages-blog-tech-and-beyond-js" */),
  "component---src-pages-cookies-js": () => import("./../src/pages/cookies.js" /* webpackChunkName: "component---src-pages-cookies-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-js": () => import("./../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-privacy-js": () => import("./../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-templates-blogpost-template-js": () => import("./../src/templates/blogpostTemplate.js" /* webpackChunkName: "component---src-templates-blogpost-template-js" */),
  "component---src-templates-case-template-js": () => import("./../src/templates/caseTemplate.js" /* webpackChunkName: "component---src-templates-case-template-js" */)
}

